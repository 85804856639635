import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DateRange } from "react-date-range";
import "./styles/ModalBody.css";
import { useState } from "react";
import { getLineGraphData } from "./AdminDashboardClient"
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
const moment = require("moment");

const getModalStyle = () => {
  const top = 30;
  const left = 43;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  body: {
    position: "absolute",
    width: "61vw",
    height: "20vw",
    boxShadow: theme.shadows[10],
  },
}));

const ModalBody = (props) => {
  const [errorMessage, setError] = useState("");
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  let currentDate = new Date(new Date().setUTCHours(0, 0, 0, 0));
  const [state, setState] = useState([
    {
      startDate: props.startDate,
      endDate: props.endDate,
      key: "selection",
    },
  ]);
  const handleOnChange = (ranges) => {
    const { selection } = ranges;
    setState([selection]);
  };

  const handleSetDate = () => {
    let start = new Date(moment(state[0]["startDate"]).format("YYYY-MM-DD"));
    let end = new Date(moment(state[0]["endDate"]).format("YYYY-MM-DD"));
    let startDate = new Date(start);
    startDate.setDate(startDate.getDate() + 30);
    let dates = [];
    if (end < startDate) {
      props.setDates(start, end);
      dates.push(moment(start).format("D MMM"));
    } else {
      setError("Select a date range within 31 days");
    }
    // getLineGraphData(props.setParams, props.selectedMetrics, new Date(start), new Date(end), dates);
    props.setIsMenuVisible(false);
  };
  return (
    <div style={modalStyle} className={classes.body}>
      <div className="calendar-modal-wrapper">
        <div className="modalBodyWrapper">
          <DateRange
            style={{ height: "30vw", width: "30vw" }}
            onChange={handleOnChange}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={state}
            direction="horizontal"
            maxDate={currentDate}
            rangeColors={["#444BAB"]}
            fixedHeight={true}
          />
          <div className="divider"></div>
          <div className="successCancelButton">
            <button onClick={handleSetDate} className="setDateRange">
              Set Date
            </button>
            <button onClick={props.onCancel} className="cancelDateRange">
              Cancel
            </button>
          </div>
          <div style={{ textAlign: "center" }}>
            <p className="error-input">{errorMessage}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalBody;
