import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import { LIKELY_STATUS } from "./SalesDashboardConstants";
import { postLikelinessDetails } from "./SalesDashboardClient";

export default function LikelinessDropdown(props) {
    const [likeliness, setLikeliness] = useState(props.params.data.Likeliness);
    const handleLikelinessChange = (e) => {
        setLikeliness(e.target.value);
        let input = {
            email: props.params.data.EmailId,
            likeliness: e.target.value,
        }
        postLikelinessDetails(input)
    };

    return (
        <div>
            <Select
                sx={{
                    "& fieldset": { border: "none" },
                }}
                style={{
                    fontStyle: "normal",
                    fontFamily: "Lato-Regular",
                    color: "#474D54",
                }}
                value={likeliness}
                onChange={(e) => handleLikelinessChange(e)}
                className="dropdown-input"
            >
                {LIKELY_STATUS.map((status) => (
                    <MenuItem value={status.status}>{status.status}</MenuItem>
                ))}
            </Select>
        </div>
    );
}
