import axios from "axios";
import { setAuthorizationCookie } from "../../CookieManager/CookieManager";
import { STATUS_CODES } from "../../common/constants";

const sendOtp = (data, setErrorMessage, setLoader, setDisplayOtpInput) => {
    axios
        .post(process.env.REACT_APP_BACKEND_ADMIN_URL + "/login/sendOtp", data, {
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then((Response) => {
            setLoader(false);
            if (Response.status === STATUS_CODES.HTTP_SUCCESS)
                //otp is sent via sms, display otp input on the frontend
                setDisplayOtpInput(true);
            console.log(Response);
        })
        .catch((error) => {
            setLoader(false);
            error && error.response && error.response.data && error.response.data.error
                ? setErrorMessage(error.response.data.error)
                : setErrorMessage("Something went Wrong! Please try again");
        });
};

const verifyOtp = (data, setLoader, setErrorMessage, redirectUser) => {
    axios
        .post(process.env.REACT_APP_BACKEND_ADMIN_URL + "/login/verifyOtp", data, {
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then((Response) => {
            setLoader(false);
            console.log("response is ", Response);
            if (Response.status === STATUS_CODES.HTTP_SUCCESS) {
                console.log("response is ", Response);
                if (Response.data && Response.data.token) {
                    setAuthorizationCookie(Response.data.token);
                    redirectUser("/home");
                }
            }
        })
        .catch((error) => {
            setLoader(false);
            error && error.response && error.response.data && error.response.data.error
                ? setErrorMessage(error.response.data.error)
                : setErrorMessage("Something went Wrong! Please try again");
        });
};
export { sendOtp, verifyOtp };
