import React, { useState } from "react";
import Header from "../Header/Header";
import { getMenuItems } from "../menuItems/AdminDashboardMenu";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import CourseSelectDropDown from "../CourseSelectDropDown/CourseSelectDropDown";
import "./styles/file.css";
import LineChart from "./LineChart";
import DonutChart from "./DonutChart";
import ExpiredChart from "./ExpiredChart";
import Overview from "./Overview";
import WeeklyPoints from "./WeeklyPoints";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    appBar: {
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        height: "4.6rem",
    },
    menuButton: {
        color: "#888888",
    },
    hide: {
        display: "none",
    },
}));

export default function AdminDashboard() {
    const classes = useStyles();
    const [isMenuVisible, setIsMenuVisible] = useState(false);

    return (
        <section>
            <Header menuItems={getMenuItems()} menuLoading={false} menuLoadingError={false}>
                <div className="header-overview">
                    Overview
                </div>
                <CourseSelectDropDown/>
                <div>
                    <Overview />
                </div>
                <div className="ml-2 header-overview">
                    Subscriptions
                    
                </div>
                <div className="subscriptions-chart-section">
                    <div className="line-chart-container">
                        <LineChart
                            type="line-chart"
                            isMenuVisible={isMenuVisible}
                            setIsMenuVisible={setIsMenuVisible}
                        />
                    </div>
                    <div className="expired-chart-container">
                        <ExpiredChart />
                    </div>
                </div>
                <div className="points-based-section">
                    <div className="points-chart-container">
                        <DonutChart type="donut-chart" />
                    </div>
                    <div className="weekly-data-container">
                        <WeeklyPoints/>
                    </div>
                </div>
            </Header>
        </section>
    );
}
