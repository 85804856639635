import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Doughnut } from "react-chartjs-2";
import { getExpiredChartData } from "./AdminDashboardClient";
import { useGlobalContext } from "../../context/GlobalContext";
import iIcon from "../../resources/images/i_icon.svg";
import "./styles/file.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Chart as expiredChart, ArcElement, Legend, Tooltip } from "chart.js";
expiredChart.register(ArcElement, Legend, Tooltip);

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    appBar: {
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        height: "4.6rem",
    },
    menuButton: {
        color: "#888888",
    },
    hide: {
        display: "none",
    },
}));

export default function ExpiredChart() {
    const classes = useStyles();
    const [expiredChart, setExpiredChartData] = useState({});
    const [loading, setLoading] = React.useState(false);
    const { courseId } = useGlobalContext();
    const stateForTotalPointsDoughnut = {
        labels: ["Placed", "Ready to Interview", "Active Users", "Inactive Users"],
        datasets: [
            {
                backgroundColor: ["#7AD68F", "#F1E897", "#9DDBE2", "#E07474"],
                data: [0, 0, expiredChart.ActiveUsers, expiredChart.InactiveUsers],
                borderWidth: 1,
                Text: "total",
            },
        ],
    };
    const plugin = {
        id: "legendDistance",
        beforeInit(chart, args, opts) {
            // Get reference to the original fit function
            const originalFit = chart.legend.fit;

            // Override the fit function
            chart.legend.fit = function fit() {
                // Call original function and bind scope in order to use `this` correctly inside it
                originalFit.bind(chart.legend)();
                // Change the height as suggested in another answers
                this.width += opts.padding || 0;
            };
        },
    };
    useEffect(() => {
        getExpiredChartData(setExpiredChartData, setLoading, courseId);
    }, [courseId]);

    return (
        <section>
            <div className=" ml-2 progress-chart-heading">
                Expired Users
                <div class="i-button-tooltip">
                    <img src={iIcon} className="i-button" />
                    <span class="tooltiptext">Subscriptions which are expired</span>
                </div>
            </div>
            <div className="progress-chart-container">
                <div className="doughnut">
                    <div className="doughnut-middle-design-expired">
                        {expiredChart.ActiveUsers + expiredChart.InactiveUsers} <br />
                        <div className="doughnut-middle-text">Expired Users</div>
                    </div>
                    <div className="doughnut-chart" onMouseEnter={() => {}} onMouseLeave={() => {}}>
                        {loading ? (
                            <div className="container mt-1 mb-1 text-center pt-1 pb-1">
                                <CircularProgress />
                            </div>
                        ) : (
                            <Doughnut
                                data={stateForTotalPointsDoughnut}
                                hoverBackgroundColor="#fff"
                                plugins={[plugin]}
                                options={{
                                    cutout: 85,
                                    plugins: {
                                        legend: {
                                            display: false,
                                            align: "center",
                                            position: "bottom",
                                            labels: {
                                                usePointStyle: true,
                                                padding: 20,
                                            },
                                        },
                                        tooltip: {
                                            displayColors: false,
                                            backgroundColor: "#F3F3F3",
                                            titleFont: {
                                                size: 15,
                                            },
                                            bodyFont: {
                                                size: 15,
                                            },
                                            callbacks: {
                                                label: (context) => {
                                                    let sumOfDoughnutData = 0;
                                                    for (
                                                        let i = 0;
                                                        i < context.chart.legend.legendItems.length;
                                                        i++
                                                    ) {
                                                        sumOfDoughnutData += parseInt(
                                                            context.dataset.data[i]
                                                        );
                                                    }
                                                    const percentagevalue =
                                                        sumOfDoughnutData == 0
                                                            ? 0
                                                            : (
                                                                  (context.raw /
                                                                      sumOfDoughnutData) *
                                                                  100
                                                              ).toFixed(1);
                                                    const arrayLine = [
                                                        `${context.label}: ${context.raw}`,
                                                        `${context.label}(%): ${percentagevalue}%`,
                                                    ];
                                                    return arrayLine;
                                                },
                                                labelTextColor: function (context) {
                                                    return "#343A40";
                                                },
                                            },
                                        },
                                    },
                                    responsive: true,
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className="legend-for-expire-chart">
                <div className="expired-chart-legend tooltip">
                    <div className="legend-circle-icon placed-legend"></div>
                    Placed
                    <div class="i-button-tooltip">
                        <img src={iIcon} className="i-button" />
                        <span class="tooltiptext">Expired users who are placed </span>
                    </div>
                </div>
                <div className="expired-chart-legend tooltip">
                    <div className="legend-circle-icon interview-legend"></div>
                    Ready to Interview
                    <div class="i-button-tooltip">
                        <img src={iIcon} className="i-button" />
                        <span class="tooltiptext">Expired users who are ready for interview </span>
                    </div>
                </div>
                <div className="expired-chart-legend tooltip">
                    <div className="legend-circle-icon active-legend"></div>
                    Active users
                    <div class="i-button-tooltip">
                        <img src={iIcon} className="i-button" />
                        <span class="tooltiptext">Active users while subscription expiry </span>
                    </div>
                </div>
                <div className="expired-chart-legend tooltip">
                    <div className="legend-circle-icon inactive-legend"></div>
                    Inactive users
                    <div class="i-button-tooltip">
                        <img src={iIcon} className="i-button" />
                        <span class="tooltiptext">Inactive users while subscription expiry</span>
                    </div>
                </div>
            </div>
        </section>
    );
}
