import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import { getMenuItems } from "../menuItems/AdminDashboardMenu";
import { Grid, makeStyles } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Snackbar from "@mui/material/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Button, Alert } from "@mui/material";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import { Course_Id, Discount_Category, PLAN_TYPE } from "./DiscountCouponConstant";
import "./DiscountCoupon.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
    postSubscriptionDiscountCoupon,
    postUpgradeDiscountCoupon,
    getPlanDetails,
} from "./DiscountClient";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiFormControl-root": {
            width: "80%",
            margin: theme.spacing(1),
        },
    },
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),
    },
    header: {
        fontFamily: "Poppins-SemiBold",
        marginLeft: "2.8vw",
    },
    submitButton: {
        fontFamily: "Poppins-SemiBold",
        color: "#fff",
        width: "80%",
        height: "55px",
        paddingTop: "1vw",
    },
}));

function DiscountCoupon() {
    const classes = useStyles();

    const [snackbarOpen, setSnackBarOpen] = useState(false);
    const [snackbarClose, setSnackBarClose] = useState(false);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const [expiryDate, setExpiryDate] = useState("");
    const [discountAmount1, setDiscountAmount1] = useState("");
    const [discountAmount2, setDiscountAmount2] = useState("");
    const [discountAmount3, setDiscountAmount3] = useState("");
    const [couponCode, setCouponCode] = useState("");
    const [discountCategory, setDiscountCategory] = useState("");
    const [courseApplicable, setCourseApplicable] = useState("");
    const [subscriptionDetails, setSubscriptionDetails] = useState([]);
    const [upgradePlansDetails, setUpgradePlansDetails] = useState([]);
    const [discountAmountDetails, setDiscountAmountDetails] = useState([]);

    useEffect(() => {
        setExpiryDate("");
        setDiscountAmount1("");
        setDiscountAmount2("");
        setDiscountAmount3("");
        setCouponCode("");
        setDiscountCategory("");
        setCourseApplicable("");
        setSubscriptionDetails([]);
        setUpgradePlansDetails([]);
    }, [snackbarOpen]);

    useEffect(() => {
        let data = {
            course_id: parseInt(courseApplicable),
            discount_category: parseInt(discountCategory),
        };
        if (courseApplicable && discountCategory) {
            setDiscountAmountDetails([]);

            if (parseInt(discountCategory) === PLAN_TYPE.SUBSCRIPTION) {
                setUpgradePlansDetails([]);
                getPlanDetails(
                    data,
                    setSubscriptionDetails,
                    setDiscountAmountDetails,
                    setSnackBarClose,
                    setLoading,
                    setError
                );
            } else if (parseInt(discountCategory) === PLAN_TYPE.UPGRADE) {
                setSubscriptionDetails([]);
                getPlanDetails(
                    data,
                    setUpgradePlansDetails,
                    setDiscountAmountDetails,
                    setSnackBarClose,
                    setLoading,
                    setError
                );
            }
        }
    }, [courseApplicable, discountCategory]);

    const handleGenerateCouponButtonClick = (e) => {
        e.preventDefault();
        if (parseInt(discountCategory) === PLAN_TYPE.SUBSCRIPTION) {
            let couponDetails = {
                Coupon_Code: couponCode,
                Discount_Amounts: discountAmountDetails,
                Discount_Category: parseInt(discountCategory),
                Expiry_Date: expiryDate,
                Subscription_Details: subscriptionDetails,
            };
            postSubscriptionDiscountCoupon(
                couponDetails,
                setSnackBarOpen,
                setSnackBarClose,
                setLoading,
                setError
            );
        } else if (parseInt(discountCategory) === PLAN_TYPE.UPGRADE) {
            let couponDetails = {
                Coupon_Code: couponCode,
                Discount_Amounts: discountAmountDetails,
                Discount_Category: parseInt(discountCategory),
                Expiry_Date: expiryDate,
                UpgradePlans_Details: upgradePlansDetails,
            };
            postUpgradeDiscountCoupon(
                couponDetails,
                setSnackBarOpen,
                setSnackBarClose,
                setLoading,
                setError
            );
        }
    };

    const handleCourseChange = (e) => {
        e.preventDefault();
        setCourseApplicable(e.target.value);
    };

    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackBarOpen(false);
    };

    const handleErrorSnackbarOpen = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackBarClose(false);
        setError("");
    };

    const handleDiscountAmountDetails = (e, index) => {
        let temp = discountAmountDetails.slice();
        temp[index] = parseFloat(e.target.value);
        setDiscountAmountDetails(temp);
    };
    const getSubtopicInputs = () => {
        if (subscriptionDetails.length != 0) {
            return (
                <Box>
                    {subscriptionDetails &&
                        subscriptionDetails.map((subscriptionDetail, index) => (
                            <TextField
                                label="Discount Amount"
                                id="outlined-start-adornment"
                                sx={{ m: 1, width: "25ch" }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {subscriptionDetail.subscription_name}
                                        </InputAdornment>
                                    ),
                                }}
                                required
                                onChange={(e) => handleDiscountAmountDetails(e, index)}
                            />
                        ))}
                </Box>
            );
        } else if (upgradePlansDetails.length !== 0) {
            return (
                <Box>
                    {upgradePlansDetails &&
                        upgradePlansDetails.map((upgradePlansDetail, index) => (
                            <TextField
                                label="Discount Amount"
                                id="outlined-start-adornment"
                                sx={{ m: 1, width: "25ch" }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {upgradePlansDetail.upgrade_plans_name}
                                        </InputAdornment>
                                    ),
                                }}
                                required
                                onChange={(e) => handleDiscountAmountDetails(e, index)}
                            />
                        ))}
                </Box>
            );
        }
    };
    return (
        <section>
            <Header menuItems={getMenuItems()} menuLoading={false} menuLoadingError={false}>
                <div className="discount-coupon-form-container">
                    <div className={classes.header}>
                        <h1>Generate Discount Coupon</h1>
                    </div>
                    <Paper className={classes.pageContent}>
                        <form
                            className={classes.root}
                            onSubmit={(e) => {
                                handleGenerateCouponButtonClick(e);
                            }}
                        >
                            <Grid container>
                                <Grid item xs={6}>
                                    <FormControl required sx={{ m: 1, width: 300 }}>
                                        <InputLabel id="demo-simple-select-helper-label">
                                            Course Applicable
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            value={courseApplicable}
                                            onChange={(e) => {
                                                handleCourseChange(e);
                                            }}
                                            label="Course Applicable"
                                        >
                                            {Course_Id.map((option) => (
                                                <MenuItem value={option.value} key={option.value}>
                                                    {option.text}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <FormControl required sx={{ m: 1, minWidth: 12 }}>
                                        <InputLabel id="demo-simple-select-helper-label">
                                            Discount Category
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            label="Discount Category"
                                            value={discountCategory}
                                            onChange={(e) => setDiscountCategory(e.target.value)}
                                        >
                                            {Discount_Category.map((option) => (
                                                <MenuItem value={option.value} key={option.value}>
                                                    {option.text}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {getSubtopicInputs()}
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        label="Coupon Code"
                                        name="duration"
                                        value={couponCode}
                                        onChange={(e) => setCouponCode(e.target.value)}
                                    />
                                    <DatePicker
                                        selected={expiryDate}
                                        onChange={(date) => setExpiryDate(date)}
                                        dateFormat="d MMMM, yyyy h:mm aa"
                                        showTimeSelect
                                        className="date-picker"
                                        placeholderText="Expiry Date *"
                                        required
                                    />
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        className={classes.submitButton}
                                        style={{ background: "#444BAB", margin: "8px" }}
                                    >
                                        Generate Coupon
                                    </Button>
                                </Grid>

                                <Snackbar
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "center",
                                    }}
                                    open={snackbarOpen}
                                    autoHideDuration={4000}
                                    onClose={handleErrorSnackbarClose}
                                >
                                    <Alert severity="success" onClose={handleErrorSnackbarClose}>
                                        Discount code created successfully!
                                    </Alert>
                                </Snackbar>

                                <Snackbar
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "center",
                                    }}
                                    open={snackbarClose}
                                    autoHideDuration={4000}
                                    onClose={handleErrorSnackbarOpen}
                                >
                                    <Alert severity="error" onClose={handleErrorSnackbarOpen}>
                                        {error}
                                    </Alert>
                                </Snackbar>
                            </Grid>
                        </form>
                    </Paper>
                    {loading ? (
                        <div
                            className="container mt-1 mb-1 text-center pt-1 pb-1"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <CircularProgress />
                            <h4>Updating...</h4>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </Header>
        </section>
    );
}

export default DiscountCoupon;
