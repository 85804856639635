import { useState, useEffect } from "react";
import { postCallerDetails } from "./SalesDashboardClient";
import { CALLERS } from "./SalesDashboardConstants";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    inputRoot: {
        "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            border: "none",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "none",
        },
    },
}));

const CallerButton = (props) => {
    const classes = useStyles();
    const [caller, setCaller] = useState(props.params.data.CallerName);
    useEffect(() => {
        if (caller) {
            let input = {
                Email: props.params.data.EmailId,
                Caller: caller,
            };
            postCallerDetails(input);
        }
    }, [caller]);
    return (
        <div>
            <Autocomplete
                classes={classes}
                freeSolo
                options={CALLERS.map((names) => names.name)}
                renderInput={(params) => <TextField {...params} />}
                onChange={(event, newValue) => {
                    setCaller(newValue);
                }}
                value={caller}
            />
        </div>
    );
};

export default CallerButton;
