import { Box, Card, CardContent, Grid, ListItem, Toolbar, Typography } from "@material-ui/core";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import "./styles/Overview.css";
import iIcon from "../../resources/images/i_icon.svg";
import Tooltip from "@material-ui/core/Tooltip";
import {
    getMonthlySubscription,
    getTotalSubscription,
    getTotalInactiveUsers,
} from "./AdminDashboardClient";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useState, useEffect } from "react";
import { useGlobalContext } from "../../context/GlobalContext";

const useStyle = makeStyles((theme) => ({
    card: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
    },
    iBtnTootipPlacement: {
        backgroundColor: "#F3F3F3",
        borderRadius: "2px",
        margin: "3px 12px",
    },
}));
const Overview = () => {
    const classes = useStyle();
    const { courseId } = useGlobalContext();
    const [subscribedUsers, setSubscribedUsers] = useState({});
    const [monthlySubscribedUsers, setMonthlySubscribedUsers] = useState({});
    const [inactiveUsers, setInactiveUsers] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getTotalSubscription(setSubscribedUsers,courseId);
        getMonthlySubscription(setMonthlySubscribedUsers, courseId);
        getTotalInactiveUsers(setInactiveUsers, setLoading, courseId);
    }, [courseId]);

    const getOverviewBox = (
        heading,
        iButtonHoverText,
        className,
        showMonthly,
        value,
        monthlyValue
    ) => {
        return (
            <Box
                className={className}
                boxShadow={2}
                item
                md={3}
                bgcolor="background.paper"
                m={1}
                p={1}
                style={{ width: "25%", height: "7vw" }}
            >
                <div className="card-headers">
                    <div className="cards-heading-text">{heading}</div>
                    <Tooltip
                        classes={{
                            tooltip: classes.iBtnTootipPlacement,
                        }}
                        title={<div className="overview-cards-hover-info">{iButtonHoverText}</div>}
                        placement="top-start"
                    >
                        <img className="image-iIcon" src={iIcon} />
                    </Tooltip>
                </div>
                {loading ? (
                    <></>
                ) : (
                    <div className="users-value">
                        {value} {showMonthly ? <>(+{monthlyValue})</> : <></>}
                    </div>
                )}
                {showMonthly ? (
                    loading ? (
                        <div className="container mt-1 mb-1 text-center pt-1 pb-1">
                            <CircularProgress />
                            <h6>Loading...</h6>
                        </div>
                    ) : (
                        <div className="last-30days_percentage">
                            +{Math.round((monthlyValue * 100) / value)}% in last 30 days
                        </div>
                    )
                ) : (
                    <></>
                )}
            </Box>
        );
    };

    return (
        <div className="wrapper">
            <div container className={classes.card} display="flex" flex-wrap="inherit">
                {getOverviewBox(
                    "Subscribed Users",
                    <div>
                        All the users who subscribe to <br />
                        the course and have not expired.
                    </div>,
                    "subscribed_user_box",
                    true,
                    subscribedUsers && subscribedUsers.Value,
                    monthlySubscribedUsers && monthlySubscribedUsers.MonthlySubscriber
                )}
                {getOverviewBox(
                    "Inactive Users",
                    <div>
                        Count of subscribed users <br /> who have scored 0 points <br /> in the last
                        15 days.
                    </div>,
                    "inactive_user_box",
                    true,
                    inactiveUsers.TotalInactiveUser,
                    inactiveUsers.TotalInactiveUsersInLast30Days
                )}
                {getOverviewBox(
                    "Ready to Interview",
                    <div>
                        Users who can be referred and are <br />
                        vetted by Placements team through <br />
                        assessments/mocks/ratings.
                    </div>,
                    "ready_to_interview_box",
                    false,
                    0,
                    0
                )}
                {getOverviewBox(
                    "Placed",
                    <div>
                        Users who are placed to companies and <br />
                        need no referrals.
                    </div>,
                    "placed_box",
                    false,
                    0,
                    0
                )}
            </div>
        </div>
    );
};

export default Overview;
