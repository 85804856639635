import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { makeStyles } from "@material-ui/core/styles";
import { COURSES, COURSE_ID } from "./CourseConstants";
import { useGlobalContext } from "../../context/GlobalContext";
const useStyles = makeStyles((theme) => ({
    DropDownWrapper: {
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(10),
    },
    formControl: {
        marginTop: theme.spacing(1),
        width: "20vw",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    select: {
        height: "10vh",
    },
    inputLabel: {
        fontSize: "4vh",
        alignSelf: "center",
    },
}));
export default function CourseSelectDropDown() {
    const classes = useStyles();

    const { setCourseId } = useGlobalContext();

    useEffect(() => {
        setCourseId(1);
    }, []);

    const handleCourseChange = (e) => {
        setCourseId(COURSE_ID[e.target.value]);
    };
    return (
        <Box className={classes.DropDownWrapper}>
            <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Course</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    defaultValue={COURSES.RENAISSANCE}
                    label="Course"
                    onChange={(e) => handleCourseChange(e)}
                >
                    {Object.values(COURSES).map((course) => (
                        <MenuItem value={course}>{course}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}
