import React from "react";
import "./loader.css";

const Loader = (props) => {
    return (
        <div className="loader-wrapper">
            <div className="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div className="loading">
                <p>Loading</p>
            </div>
        </div>
    );
};

export default Loader;
