import { useState, useEffect } from "react";
import { postRemarksDetails } from "./SalesDashboardClient";

const RemarksButton = (props) => {
    const [remarks, setRemarks] = useState(props.params.data.Remarks);
    useEffect(() => {
        if (remarks) {
            let input = {
                Email: props.params.data.EmailId,
                Remarks: remarks,
            };
            postRemarksDetails(input);
        }
    }, [remarks]);
    return (
        <>
            <div
                style={{
                    fontStyle: "normal",
                    fontFamily: "Lato-Regular",
                    color: "#474D54",
                }}
            >
                {remarks}
            </div>
        </>
    );
};

export default RemarksButton;
