import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import { postBatchDetails, postPlacementDetails } from "./StudentDirectoryClient";
const DropDown = (props) => {
    const BATCHES = {
        A: "A",
        B: "B",
        C: "C",
        D: "D",
    };
    const PLACEMENT_STATUS = {
        READY_FOR_MOCKS: "Ready for mocks",
        READY_FOR_REFERRALS: "Ready for referrals",
        PLACED: "Placed",
        NOT_READY: "Not Ready",
    };
    const [batch, setBatch] = useState(props.params.value);
    const [placementStatus, setPlacementStatus] = useState(props.params.value);

    const handleBatchChange = (e) => {
        setBatch(e.target.value);
        props.params.setValue(e.target.value);

        let input = {
            email: props.params.data.EmailID,
            batch: e.target.value,
        };
        postBatchDetails(input);
    };

    const handlePlacementStatusChange = (e) => {
        setPlacementStatus(e.target.value);
        props.params.setValue(e.target.value);
        let input = {
            email: props.params.data.EmailID,
            placementStatus: e.target.value,
        };
        postPlacementDetails(input);
    };
    let TEXT_COLOR;
    switch (placementStatus) {
        case "Ready for mocks":
            TEXT_COLOR = "#A8C10A";
            break;
        case "Ready for referrals":
            TEXT_COLOR = "#3BABC3";
            break;
        case "Placed":
            TEXT_COLOR = "#1F9C7D";
            break;
        case "Not Ready":
            TEXT_COLOR = "#CD6767";
            break;
        default:
            break;
    }
    return (
        <>
            {props.type && props.type === "BATCH" ? (
                <div>
                    <Select
                        sx={{
                            "& fieldset": { border: "none" },
                        }}
                        style={{
                            fontStyle: "normal",
                            fontFamily: "Lato-Regular",
                            color: "#474D54",
                        }}
                        value={batch}
                        onChange={handleBatchChange}
                        className="dropdown-input"
                        inputProps={{ "aria-label": "Without label" }}
                    >
                        <MenuItem value={BATCHES.A}>{BATCHES.A}</MenuItem>
                        <MenuItem value={BATCHES.B}>{BATCHES.B}</MenuItem>
                        <MenuItem value={BATCHES.C}>{BATCHES.C}</MenuItem>
                        <MenuItem value={BATCHES.D}>{BATCHES.D}</MenuItem>
                    </Select>
                </div>
            ) : props.type && props.type === "PLACEMENT" ? (
                <div>
                    <Select
                        sx={{
                            "& fieldset": { border: "none" },
                        }}
                        style={{
                            color: TEXT_COLOR,
                            fontFamily: "Lato-Regular",
                            fontStyle: "normal",
                        }}
                        value={placementStatus}
                        onChange={handlePlacementStatusChange}
                        className="dropdown-input"
                        inputProps={{ "aria-label": "Without label" }}
                    >
                        <MenuItem
                            style={{ color: "#A8C10A" }}
                            value={PLACEMENT_STATUS.READY_FOR_MOCKS}
                        >
                            {PLACEMENT_STATUS.READY_FOR_MOCKS}
                        </MenuItem>
                        <MenuItem
                            style={{ color: "#3BABC3" }}
                            value={PLACEMENT_STATUS.READY_FOR_REFERRALS}
                        >
                            {PLACEMENT_STATUS.READY_FOR_REFERRALS}
                        </MenuItem>
                        <MenuItem style={{ color: "#1F9C7D" }} value={PLACEMENT_STATUS.PLACED}>
                            {PLACEMENT_STATUS.PLACED}
                        </MenuItem>
                        <MenuItem style={{ color: "#CD6767" }} value={PLACEMENT_STATUS.NOT_READY}>
                            {PLACEMENT_STATUS.NOT_READY}
                        </MenuItem>
                    </Select>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default DropDown;
