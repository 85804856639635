import axios from "axios";
import Cookies from "universal-cookie";

const postUserPaymentDetails = (data, setSnackBarOpen, setSnackBarClose, setLoading) => {
    const cookies = new Cookies();
    setLoading(true);
    axios
        .post(
            process.env.REACT_APP_BACKEND_ADMIN_URL + "/externalPayments/postUserPaymentDetails",
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                    authorization: cookies.get("authorization"),
                },
            }
        )
        .then((Response) => {
            setSnackBarOpen(true);
            setLoading(false);
        })
        .catch((error) => {
            setLoading(false);
            setSnackBarClose(true);
            console.error("error is ", error);
        });
};

const getUserPaymentHistory = (data,setPaymentHistory,  setLoading) => {
    const cookies = new Cookies();
    setLoading(true);
    axios
        .post(
            process.env.REACT_APP_BACKEND_ADMIN_URL + "/externalPayments/getUserPaymentHistory",
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                    authorization: cookies.get("authorization"),
                },
            }
        )
        .then((response) => {
            if(response && response.data){
                setPaymentHistory(response.data);
            }else{
                setPaymentHistory([]);
            }
            setLoading(false);
        })
        .catch((error) => {
            setLoading(false);
            console.error("error is ", error);
        });
};

export { postUserPaymentDetails, getUserPaymentHistory };
