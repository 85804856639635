import axios from "axios";
import moment from "moment";
import Cookies from "universal-cookie";
import { initiateLogout } from "../../common/utility";
import { STATUS_CODES } from "../../common/constants";
import { DATA_AVERAGE, CHART_NAME } from "./AdminDashboardConstants";
const getLineGraphDataForAvgerage = (startDate, endDate, metricsType, dates, setParams, courseId) => {
    const cookies = new Cookies();
    let start = moment(startDate).format("YYYY-MM-DD");
    let end = moment(endDate).format("YYYY-MM-DD");
    let subscribedUsersData = [];
    let metricsDataListFinalData = [];
    let subscriberCount  = {
        "start_date": start,
        "end_date": end,
        "metrics_type" : CHART_NAME["Subscribed Users"],
        "course_id" : courseId
    }
    let averageData = [];
    let dataForAverage  = {
        "start_date": start,
        "end_date": end,
        "metrics_type" : DATA_AVERAGE[metricsType],
        "course_id" : courseId
    }
    axios.post(process.env.REACT_APP_BACKEND_ADMIN_URL + "/charts/getMetricsDetails", dataForAverage, {
        headers: {
            "Content-Type": "application/json",
            authorization: cookies.get("authorization")
        },
    }).then((Response) => {
        let start = new Date(startDate);
        Response.data.map((metricsData)=> {
            while(moment(metricsData.date).format("YYYY-MM-DD") !== moment(start).format("YYYY-MM-DD")){
                averageData.push(0);
                start.setDate(start.getDate() + 1);
            }
            start.setDate(start.getDate() + 1);
            averageData.push(metricsData.value);
        })
    })
    .then(()=>{
        axios.post(process.env.REACT_APP_BACKEND_ADMIN_URL + "/charts/getMetricsDetails", subscriberCount, {
            headers: {
                "Content-Type": "application/json",
                authorization: cookies.get("authorization")
            },
        }).then((Response) => {
            let start = new Date(startDate);
            Response.data.map((metricsData)=> {
                while(moment(metricsData.date).format("YYYY-MM-DD") !== moment(start).format("YYYY-MM-DD")){
                    subscribedUsersData.push(0);
                    start.setDate(start.getDate() + 1);
                }
                start.setDate(start.getDate() + 1);
                subscribedUsersData.push(metricsData.value);  
            })
            subscribedUsersData.forEach((subscriberPerDay, index) => {
                metricsDataListFinalData.push(
                    subscriberPerDay == 0 ? 0 : (averageData[index] / subscriberPerDay).toFixed(0)
                );
            });
          setParams(metricsDataListFinalData, dates);
        }).catch((err) => {
            if(err && err.response && err.response.status && err.response.status===STATUS_CODES.HTTP_UNAUTHORIZED)
                initiateLogout();
            console.log(err);
        });
    })
    .catch((err) => {
        if(err && err.response && err.response.status && err.response.status===STATUS_CODES.HTTP_UNAUTHORIZED)
            initiateLogout();
        console.log(err);
    });
}

const getTotalSubscription = (setWeeklyPoints, courseId) => {
    const cookies = new Cookies();
    let input = {
        Course_Id: courseId
    }
    axios.post( process.env.REACT_APP_BACKEND_ADMIN_URL + "/charts/getTotalSubscription", input,{
        headers: {
            "Content-Type": "application/json",
            authorization: cookies.get("authorization")
        },
    }).then((Response) => {
        console.log(Response.data);
        setWeeklyPoints(Response.data);
    }).catch((err) => {
        console.log(err);
        console.log("status is ",err.response.status)
        if(err && err.response && err.response.status && err.response.status===STATUS_CODES.HTTP_UNAUTHORIZED){
            console.log("inside logout")
            initiateLogout();
        } 
    });
}

const getMonthlySubscription = (setMonthlySubscription,courseId) => {
    const cookies = new Cookies();
    let input = {
        Course_Id: courseId
    }
    axios.post( process.env.REACT_APP_BACKEND_ADMIN_URL + "/charts/getMonthlySubscription",input, {
        headers: {
            "Content-Type": "application/json",
            authorization: cookies.get("authorization")
        },
    }).then((Response) => {
        console.log(Response.data);
        setMonthlySubscription(Response.data);
    }).catch((err) => {
        if(err && err.response && err.response.status && err.response.status===STATUS_CODES.HTTP_UNAUTHORIZED)
            initiateLogout();
        console.log(err);
    });
}

const getTotalPoints = (setTotalPoints, setLoading,courseId) => {
    const cookies = new Cookies();
    setLoading(true);
    let input = {
        Course_Id: courseId
    }
    axios.post( process.env.REACT_APP_BACKEND_ADMIN_URL + "/charts/getTotalPoints",input, {
        headers: {
            "Content-Type": "application/json",
            authorization: cookies.get("authorization")
        },
    }).then((Response) => {
        console.log(Response.data);
        setTotalPoints(Response.data);
        setLoading(false);
    }).catch((err) => {
        if(err && err.response && err.response.status && err.response.status===STATUS_CODES.HTTP_UNAUTHORIZED)
            initiateLogout();
        console.log(err);
        setLoading(false);
    });
}

const getMetricsDetails = (setMetricsDetails, data) => {
    const cookies = new Cookies();
    axios.post(process.env.REACT_APP_BACKEND_ADMIN_URL + "/charts/getMetricsDetails", data, {
        headers: {
            "Content-Type": "application/json",
            authorization: cookies.get("authorization")
        },
    }).then((Response) => {
        console.log(Response.data);
        setMetricsDetails(Response.data);
    }).catch((err) => {
        if(err && err.response && err.response.status && err.response.status===STATUS_CODES.HTTP_UNAUTHORIZED)
            initiateLogout();
        console.log(err);
    });
}

const getWeeklyTotalSubsPoints = (setWeeklyTotalSubsPoints, courseId) => {
    const cookies = new Cookies();
    let input = {
        Course_Id: courseId
    }
    axios.post(process.env.REACT_APP_BACKEND_ADMIN_URL + "/charts/getWeeklyTotalSubsPoints", input,{
        headers: {
            "Content-Type": "application/json",
            authorization: cookies.get("authorization")
        },
    }).then((Response) => {
        console.log(Response.data);
        setWeeklyTotalSubsPoints(Response.data);
    }).catch((err) => {
        if(err && err.response && err.response.status && err.response.status===STATUS_CODES.HTTP_UNAUTHORIZED)
            initiateLogout();
        console.log(err);
    });
}

const getLineGraphData = (setParams, metricsType, startDate, endDate, dates, courseId) => {
    const cookies = new Cookies();
    let start = moment(startDate).format("YYYY-MM-DD");
    let end = moment(endDate).format("YYYY-MM-DD");
    console.log(start, end, metricsType);
    if (metricsType == CHART_NAME["Average Points"] || metricsType == CHART_NAME["Average Watch Time"] ) {
      getLineGraphDataForAvgerage(startDate, endDate, metricsType, dates, setParams, courseId);
    }
     else {
    let data  = {
        "start_date": start,
        "end_date": end,
        "metrics_type" : metricsType,
        "course_id" : courseId
    }
    axios.post(process.env.REACT_APP_BACKEND_ADMIN_URL + "/charts/getMetricsDetails", data, {
        headers: {
            "Content-Type": "application/json",
            authorization: cookies.get("authorization")
        },
    }).then((Response) => {
        console.log(Response.data);
        let metricsDataList = [];
        let start = new Date(startDate);
		Response.data && Response.data.map((metricsData)=> {
            console.log(moment(metricsData.date).format("YYYY-MM-DD"), moment(start).format("YYYY-MM-DD"));
            while(moment(metricsData.date).format("YYYY-MM-DD") !== moment(start).format("YYYY-MM-DD")){
                metricsDataList.push(0);
                start.setDate(start.getDate() + 1);
            }
            start.setDate(start.getDate() + 1);
            metricsDataList.push(metricsData.value);  
        })
        setParams(metricsDataList, dates);
    }).catch((err) => {
        if(err && err.response && err.response.status && err.response.status===STATUS_CODES.HTTP_UNAUTHORIZED)
            initiateLogout();
        console.log(err);
    });
}
}

const getExpiredChartData = (setExpiredChartData, setLoading,courseId) => {
    const cookies = new Cookies();
    setLoading(true);
    let input = {
        Course_Id: courseId
    }
    axios.post(process.env.REACT_APP_BACKEND_ADMIN_URL + "/charts/getExpiredChartData",input, {
        headers: {
            "Content-Type": "application/json",
            authorization: cookies.get("authorization")

        },
    }).then((Response) => {
        console.log(Response.data);
        setExpiredChartData(Response.data);
        setLoading(false);
    }).catch((err) => {
        if(err && err.response && err.response.status && err.response.status===STATUS_CODES.HTTP_UNAUTHORIZED)
            initiateLogout();
        console.log(err);
        setLoading(false);
    });
}

const getTotalInactiveUsers = (setTotalInactiveUsers, setLoading, courseId) => {
    const cookies = new Cookies();
    setLoading(true);
    let input = {
        Course_Id : courseId
    }
    axios.post(process.env.REACT_APP_BACKEND_ADMIN_URL + "/charts/getTotalInactiveUsers",input, {
        headers: {
            "Content-Type": "application/json",
            authorization: cookies.get("authorization")
        },
    }).then((Response) => {
        console.log(Response.data);
        setTotalInactiveUsers(Response.data);
        setLoading(false);
    }).catch((err) => {
        if(err && err.response && err.response.status && err.response.status===STATUS_CODES.HTTP_UNAUTHORIZED)
            initiateLogout();
        console.log(err);
        setLoading(false);
    });
}

export {getTotalSubscription, getMetricsDetails, getMonthlySubscription, getTotalPoints, getTotalInactiveUsers, getWeeklyTotalSubsPoints, getLineGraphData, getExpiredChartData};