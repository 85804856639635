import React, { useEffect, useState, forwardRef } from "react";
import Header from "../Header/Header";
import { getMenuItems } from "../menuItems/AdminDashboardMenu";
import "./ExtendSubscription.css";
import { Grid, makeStyles } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";

import { Button, Alert } from "@mui/material";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import { isValidEmail, isValidCourseId } from "../../common/utility";
import { postUserPaymentDetails, getUserPaymentHistory } from "./ExtendSubscriptionClient";
import { Course_Id, Payment_Status, Renaissance_Course_ID } from "./ExtendSubscriptionConstant";

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiFormControl-root": {
            width: "80%",
            margin: theme.spacing(1),
        },
    },
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),
    },
    header: {
        fontFamily: "Poppins-SemiBold",
        marginLeft: "2.8vw",
    },
    selectDropdown: {
        width: "80%",
        margin: "8px",
    },
    submitButton: {
        fontFamily: "Poppins-SemiBold",
        color: "#fff",
        width: "80%",
        height: "55px",
    },
}));

function ExtendSubscription() {
    const classes = useStyles();

    const [email, setEmail] = useState("");
    const [orderId, setOrderId] = useState("");
    const [amount, setAmount] = useState("");
    const [duration, setDuration] = useState("");
    const [couseId, setCourseId] = useState(Renaissance_Course_ID);
    const [snackbarOpen, setSnackBarOpen] = useState(false);
    const [snackbarClose, setSnackBarClose] = useState(false);
    const [loading, setLoading] = useState(false);

    const [paymentHistory, setPaymentHistory] = useState([]);
    const [validationError, setValidationError] = useState("");
    useEffect(() => {
        setEmail("");
        setOrderId("");
        setAmount("");
        setDuration("");
        setCourseId(Renaissance_Course_ID);
    }, [snackbarOpen]);

    const handleExtendSubscriptionButtonClick = (e) => {
        e.preventDefault();
        let paymentDetails = {
            Email: email,
            Order_ID: orderId,
            Amount: parseInt(amount),
            Status: Payment_Status,
            Duration: parseInt(duration),
            Course_Id: parseInt(couseId),
        };
        postUserPaymentDetails(paymentDetails, setSnackBarOpen, setSnackBarClose, setLoading);
    };

    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackBarOpen(false);
    };

    const handleErrorSnackbarOpen = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackBarClose(false);
    };
    const handleViewPaymentHistory = () => {
        if (!isValidEmail(email)) {
            setValidationError("Invalid email");
            return;
        }
        if (!isValidCourseId(couseId)) {
            setValidationError("Invalid Course");
            return;
        }
        setValidationError("");
        let data = {
            email: email,
            course_id: parseInt(couseId),
        };
        getUserPaymentHistory(data, setPaymentHistory, setLoading);
    };
    const viewPaymentHistory = () => {
        return (
            <div className="view-payment-history-wrapper">
                <h1> Payment History</h1>
                <div className="payment-history-button" onClick={() => handleViewPaymentHistory()}>
                    <button>Click here to view payment history</button>
                </div>
                {validationError && <span className="error-text">{validationError}</span>}
                {paymentHistory.length !== 0 && (
                    <table>
                        <tr>
                            <th>Email</th>
                            <th>Order_ID</th>
                            <th>Amount</th>
                            <th>Creation_Date</th>
                            <th>Payment_Date</th>
                            <th>Status</th>
                            <th>Duration</th>
                            <th>Is_Upgrade</th>
                            <th>Course_Id</th>
                        </tr>

                        {paymentHistory &&
                            paymentHistory.map((paymentHistoryDetails) => (
                                <tr>
                                    <td>{paymentHistoryDetails.Email}</td>
                                    <td>{paymentHistoryDetails.Order_ID}</td>
                                    <td>{paymentHistoryDetails.Amount}</td>
                                    <td>{paymentHistoryDetails.Creation_Date}</td>
                                    <td>{paymentHistoryDetails.Payment_Date}</td>
                                    <td>{paymentHistoryDetails.Status}</td>
                                    <td>{paymentHistoryDetails.Duration}</td>
                                    <td>{paymentHistoryDetails.Is_Upgrade}</td>
                                    <td>{paymentHistoryDetails.Course_Id}</td>
                                </tr>
                            ))}
                    </table>
                )}
            </div>
        );
    };
    return (
        <section>
            <Header menuItems={getMenuItems()} menuLoading={false} menuLoadingError={false}>
                <div className="extend-subscription-form-container">
                    <div className={classes.header}>
                        <h1>Extend subscription form</h1>
                    </div>
                    <Paper className={classes.pageContent}>
                        <form
                            className={classes.root}
                            onSubmit={(e) => {
                                handleExtendSubscriptionButtonClick(e);
                            }}
                        >
                            <Grid container>
                                <Grid item xs={6}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        type="email"
                                        label="Email"
                                        name="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <TextField
                                        required
                                        variant="outlined"
                                        label="Order ID"
                                        name="orderId"
                                        value={orderId}
                                        onChange={(e) => setOrderId(e.target.value)}
                                    />
                                    <TextField
                                        required
                                        variant="outlined"
                                        type="number"
                                        label="Amount"
                                        name="amount"
                                        value={amount}
                                        onChange={(e) => setAmount(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        type="number"
                                        label="Duration"
                                        name="duration"
                                        value={duration}
                                        onChange={(e) => setDuration(e.target.value)}
                                    />
                                    <FormControl required sx={{ m: 1, minWidth: 12 }}>
                                        <InputLabel id="demo-simple-select-helper-label">
                                            Course ID
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={couseId}
                                            label="Course ID"
                                            onChange={(e) => setCourseId(e.target.value)}
                                        >
                                            {Course_Id.map((option, index) => (
                                                <MenuItem value={option.value} key={option.value}>
                                                    {option.text}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        className={classes.submitButton}
                                        style={{ background: "#444BAB", margin: "8px" }}
                                    >
                                        Extend Subscription
                                    </Button>
                                </Grid>

                                <Snackbar
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "center",
                                    }}
                                    open={snackbarOpen}
                                    autoHideDuration={4000}
                                    onClose={handleErrorSnackbarClose}
                                >
                                    <Alert severity="success" onClose={handleErrorSnackbarClose}>
                                        Payment details posted successfully
                                    </Alert>
                                </Snackbar>

                                <Snackbar
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "center",
                                    }}
                                    open={snackbarClose}
                                    autoHideDuration={4000}
                                    onClose={handleErrorSnackbarOpen}
                                >
                                    <Alert severity="error" onClose={handleErrorSnackbarOpen}>
                                        Oops! Something Went Wrong
                                    </Alert>
                                </Snackbar>
                            </Grid>
                        </form>
                    </Paper>
                    {loading ? (
                        <div
                            className="container mt-1 mb-1 text-center pt-1 pb-1"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <CircularProgress />
                            <h4>Updating...</h4>
                        </div>
                    ) : (
                        <></>
                    )}

                    {viewPaymentHistory()}
                </div>
            </Header>
        </section>
    );
}

export default ExtendSubscription;
