import Cookies from "universal-cookie";
import { monthNames } from "./constants";
import { REGEX, COURSE_IDS } from "./constants";

function dateFormat(dateInYearFormat) {
    var date = dateInYearFormat && dateInYearFormat.split("-")[0];
    var month = dateInYearFormat && dateInYearFormat.split("-")[1];
    return `${date} ${monthNames[parseInt(month)]}`;
}

const initiateLogout = () => {
    const cookies = new Cookies();
    if (cookies.get("authorization")) {
        cookies.remove("authorization", {
            path: "/",
            domain: "admin.programmingpathshala.com",
        });
    }

    window.open(process.env.REACT_APP_FRONTEND_ADMIN_URL + "/login", "_self");
};
const getReadableDateMonth = (input) => {
    let monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];
    let day = new Date(input);
    return day.getDate() + " " + monthNames[day.getMonth()] + " " + day.getFullYear();
};
const isNULLEMPTYORUNDEFINED = (value) => {
    return value === null || value === undefined || value === "";
};
const isValidEmail = (email) => {
    return !isNULLEMPTYORUNDEFINED(email) && REGEX.EMAIL.test(String(email).toLowerCase());
};

const isValidCourseId = (courseId) => {
    return !isNULLEMPTYORUNDEFINED(courseId) && COURSE_IDS.includes(parseInt(courseId));
};
export { dateFormat, initiateLogout, getReadableDateMonth, isValidEmail, isValidCourseId };
