import axios from "axios";
import Cookies from "universal-cookie";

const getUsersInfo = (setRowData, setPageLoader) => {
    const cookies = new Cookies();
    setPageLoader(true);
    return axios
        .get(process.env.REACT_APP_BACKEND_ADMIN_URL + "/salesDashboard/getUsersInfo", {
            headers: {
                "Content-Type": "application/json",
                authorization: cookies.get("authorization"),
            },
        })
        .then((Response) => {
            setRowData(Response.data);
            setPageLoader(false);
        })
        .catch((error) => {
            console.log("Error is " + error);
            setPageLoader(false);
        });
};

const postRemarksDetails = (data) => {
    const cookies = new Cookies();
    axios
        .post(
            process.env.REACT_APP_BACKEND_ADMIN_URL + "/salesDashboard/postRemarksDetails",
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                    authorization: cookies.get("authorization"),
                },
            }
        )
        .then((Response) => {
            console.log("Successfully posted remarks details");
        })
        .catch((error) => {
            console.log("error is ", error);
        });
};

const postLikelinessDetails = (data) => {
    const cookies = new Cookies();
    axios
        .post(
            process.env.REACT_APP_BACKEND_ADMIN_URL + "/salesDashboard/postLikelinessDetails",
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                    authorization: cookies.get("authorization"),
                },
            }
        )
        .then((Response) => {
            console.log("Successfully posted likeliness details");
        })
        .catch((error) => {
            console.log("error is ", error);
        });
};

const postCallerDetails = (data) => {
    const cookies = new Cookies();
    axios
        .post(
            process.env.REACT_APP_BACKEND_ADMIN_URL + "/salesDashboard/postCallerDetails",
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                    authorization: cookies.get("authorization"),
                },
            }
        )
        .then((Response) => {
            console.log("Successfully posted likeliness details");
        })
        .catch((error) => {
            console.log("error is ", error);
        });
};

export { getUsersInfo, postRemarksDetails, postLikelinessDetails, postCallerDetails};
