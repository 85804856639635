import React from "react";
import "./styles/login.css";
import PhoneInput from "react-phone-input-2";
import OtpInput from "react-otp-input";
import Loader from "../Loader/loader";
import "react-phone-input-2/lib/style.css";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { sendOtp, verifyOtp } from "./LoginClient";
import { REGEX } from "../../common/constants";
import { TextField } from "@mui/material";
export default function Login() {
    const history = useHistory();
    const [email, setEmail] = useState();
    const [otp, setOtp] = useState("");
    const [displayOtpInput, setDisplayOtpInput] = useState(false);
    const [loader, setLoader] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const redirectUser = (link) => history.push(link);
    const handleOtpChange = (otp) => {
        setOtp(otp);
    };
    const validOtp = (otp) => {
        return otp !== "" && otp !== undefined && otp !== null && REGEX.OTP.test(otp);
    };
    const validEmail = (email) => {
        return (
            email !== "" &&
            email !== null &&
            email !== undefined &&
            REGEX.EMAIL.test(String(email).toLowerCase())
        );
    };
    const sendOTP = (event) => {
        setLoader(true);
        event.preventDefault();
        if (validEmail(email)) {
            setErrorMessage("");
            let data = {
                email: email,
            };
            sendOtp(data, setErrorMessage, setLoader, setDisplayOtpInput);
        } else {
            setErrorMessage("Invalid Email");
            setLoader(false);
        }
    };
    const verifyOTP = (event) => {
        setLoader(true);
        event.preventDefault();
        //validating phone and otp
        if (validEmail(email) && validOtp(otp)) {
            setErrorMessage("");
            let data = {
                email: email,
                otp: otp,
            };
            verifyOtp(data, setLoader, setErrorMessage, redirectUser);
        } else {
            setLoader(false);
            setErrorMessage("Invalid Credentials");
        }
    };
    return (
        <div className="login">
            <div className="login-form">
                <h1 classname="login-heading">Login</h1>
                <label>Enter your email id</label>
                <input
                    onChange={(e) => {
                        setEmail(e.target.value);
                    }}
                    type="email"
                    required
                    className="email-input"
                    value={email}
                />
                <div className="otp-input">
                    {displayOtpInput ? (
                        <OtpInput
                            value={otp}
                            className="signup-otp-input-wrapper"
                            onChange={handleOtpChange}
                            numInputs={4}
                            containerStyle={{
                                justifyContent: "center",
                            }}
                            inputStyle={{
                                width: "50px",
                                height: "40px",
                                margin: "10px",
                                fontSize: "15px",
                                borderRadius: 4,
                                border: "2px solid rgb(37, 119, 145)",
                            }}
                        />
                    ) : (
                        <></>
                    )}
                </div>
                {errorMessage && <span className="error-msg">{errorMessage}</span>}
                {displayOtpInput ? (
                    <button className="submit-button" onClick={verifyOTP}>Confirm OTP</button>
                ) : (
                    <button className="submit-button" onClick={sendOTP}>Send OTP</button>
                )}
                {loader && <Loader />}
            </div>
        </div>
    );
}
