const Course_Id = [
    { value: "", text: "--Choose an option--" },
    { value: "1", text: "Renaissance" },
    { value: "2", text: "LLD" },
    { value: "3", text: "Begin with c++"},
    { value: "4", text: "Python"},
    { value: "5", text: "Frontend with React"},
    { value: "6", text: "DSA Essentials"},
    { value: "9", text: "Java Springboot"}
];

const Payment_Status = "captured";

const Renaissance_Course_ID = 1;

export { Course_Id, Payment_Status, Renaissance_Course_ID };
