import { Grid, Box } from "@material-ui/core";
import { useState, useEffect } from "react";
import {getWeeklyTotalSubsPoints} from "./AdminDashboardClient";
import { dateFormat} from "../../common/utility";
import "./styles/WeeklyPoints.css";
import { useGlobalContext } from "../../context/GlobalContext";

const WeeklyPoints = () =>{
    const [usersWeelyPoints, setUsersWeeklyPoints] = useState({});
    const {courseId} = useGlobalContext();
    useEffect(() => {
        getWeeklyTotalSubsPoints(setUsersWeeklyPoints, courseId);
    }, [courseId])
    const getPastWeekData =(usersWeelyPoints) => {
        return (
            <Box 
                m={1}
                p={1}
            >
                <div className="last-3week-section">
                    {usersWeelyPoints && dateFormat(usersWeelyPoints.start_date)}-{usersWeelyPoints && dateFormat(usersWeelyPoints.end_date)}
                </div>
                <div className="last-3week-data">
                    {usersWeelyPoints && usersWeelyPoints.points}
                </div>
            </Box>
        );
    }
    return (
        <div>
            <Grid>
                <Box className="weekly-points-cards"
                    boxShadow={2}
                    item md = {3}
                    m={1}
                    p={1}
                    >
                        <div className="header-weekly-point-cards">
                            Total points gained by Subscribed Users in <br/>
                            this week ({usersWeelyPoints && usersWeelyPoints[3] && dateFormat(usersWeelyPoints[3].start_date)}-{usersWeelyPoints && usersWeelyPoints[3] && dateFormat(usersWeelyPoints[3].end_date)})
                        </div>
                        <div className="weekly-points-cards-value">
                            {usersWeelyPoints && usersWeelyPoints[3] && usersWeelyPoints[3].points}
                        </div>
                </Box>
                <Box className="weekly-points-cards"
                    boxShadow={2}
                    item md = {3}
                    m={1}
                    p={1}
                    >
                        <div className="header-weekly-point-cards">
                            Total points gained by Subscribed Users in <br/> 
                            last 3 weeks
                        </div>
                        <Grid container className="weekwise-value">
                            {getPastWeekData(usersWeelyPoints && usersWeelyPoints[2])}
                            <div className="box-seperator-line"></div>
                            {getPastWeekData(usersWeelyPoints && usersWeelyPoints[1])}
                            <div className="box-seperator-line"></div>
                            {getPastWeekData(usersWeelyPoints && usersWeelyPoints[0])}
                        </Grid>
                </Box>
            </Grid>
        </div>
    );
    
}
export default WeeklyPoints