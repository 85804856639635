import homeMenuIcon from "../../resources/images/homeMenu.svg";
import studentDirectoryMenuIcon from "../../resources/images/studentDirectoryMenu.svg";
import externalPaymentIcon from "../../resources/images/externalPayments.svg";
import discountCoupon from "../../resources/images/discountCoupon.svg"
import salesDashboard from "../../resources/images/salesDashboard.svg"
const getMenuItems = () => {
    let menuItems = [
        {
            text: "Home",
            icon: homeMenuIcon,
            path: "/home",
            action: "",
        },
        {
            text: "Students Directory",
            icon: studentDirectoryMenuIcon,
            path: "/studentDirectory",
            action: "",
        },
        {
            text: "Extend Subscription",
            icon: externalPaymentIcon,
            path: "/extendSubscription",
            action: "",
        },
        {
            text: "Discount Coupon",
            icon: discountCoupon,
            path: "/discountCoupon",
            action: "",
        },
        {
            text: "Sales Dashboard",
            icon: salesDashboard,
            path: "/salesDashboard",
            action: "",
        },
    ];
    return menuItems;
};

export { getMenuItems };
