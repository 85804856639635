import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import { getMenuItems } from "../menuItems/AdminDashboardMenu";
import { makeStyles } from "@material-ui/core/styles";
import { Doughnut } from "react-chartjs-2";
import { getTotalPoints } from "./AdminDashboardClient";
import "./styles/file.css";
import CircularProgress from "@material-ui/core/CircularProgress";
// import LineChart from "./LineChart";
import { Chart as Donut, ArcElement, Legend, Tooltip } from "chart.js";
import { useGlobalContext } from "../../context/GlobalContext";
Donut.register(ArcElement, Legend, Tooltip);

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    appBar: {
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        height: "4.6rem",
    },
    menuButton: {
        color: "#888888",
    },
    hide: {
        display: "none",
    },
}));

export default function DonutChart() {
    const [totalPoints, setTotalPoints] = useState({});
    const [loading, setLoading] = useState(false);
    const { courseId } = useGlobalContext();
    const stateForTotalPointsDoughnut = {
        labels: [
            "0-5000",
            "5001 - 10000",
            "10001 - 15000",
            "15001 - 20000",
            "20001 - 25000",
            "25001 - 30000",
            "30001 - 35000",
            ">35000",
        ],
        datasets: [
            {
                backgroundColor: [
                    "#B6D3F4",
                    "#6499D7",
                    "#F0C1C1",
                    "#EF9494",
                    "#FFFB9E",
                    "#FBDA65",
                    "#95D8A4",
                    "#64B175",
                ],
                data: [
                    totalPoints && totalPoints.UptoFiveThousand,
                    totalPoints && totalPoints.FiveToTenThousand,
                    totalPoints && totalPoints.TenToFifteenThousand,
                    totalPoints && totalPoints.FifteenToTwentyThousand,
                    totalPoints && totalPoints.TwentyToTwentyFiveThousand,
                    totalPoints && totalPoints.TwentyFiveToThirtyThousand,
                    totalPoints && totalPoints.ThirtyToThirtyFiveThousand,
                    totalPoints && totalPoints.MoreThanThirtyFiveThousand,
                ],
                borderWidth: 1,
                Text: "total",
            },
        ],
    };

    useEffect(() => {
        getTotalPoints(setTotalPoints, setLoading, courseId);
    }, [courseId]);

    return (
        <section>
            <div className=" ml-2 progress-chart-heading">
                Points Distribution of Subscribed Users
            </div>
            <div className="points-chart">
                <div className="">
                    <div className="" onMouseEnter={() => {}} onMouseLeave={() => {}}>
                        <div className="doughnut-middle-design-points">
                            Total <br /> Points
                        </div>
                        {loading ? (
                            <div className="container mt-1 mb-1 text-center pt-1 pb-1">
                                <CircularProgress />
                                <h6>Loading...</h6>
                            </div>
                        ) : (
                            <Doughnut
                                data={stateForTotalPointsDoughnut}
                                hoverBackgroundColor="#fff"
                                options={{
                                    cutout: 85,
                                    plugins: {
                                        legend: {
                                            display: true,
                                            align: "center",
                                            position: "right",
                                            labels: {
                                                usePointStyle: true,
                                                padding: 20,
                                            },
                                        },
                                        tooltip: {
                                            displayColors: false,
                                            backgroundColor: "#F3F3F3",
                                            titleFont: {
                                                size: 15,
                                            },
                                            bodyFont: {
                                                size: 14,
                                            },
                                            callbacks: {
                                                label: (context) => {
                                                    let sumOfDoughnutData = 0;
                                                    for (
                                                        let i = 0;
                                                        i < context.chart.legend.legendItems.length;
                                                        i++
                                                    ) {
                                                        sumOfDoughnutData += parseInt(
                                                            context.dataset.data[i]
                                                        );
                                                    }
                                                    const percentagevalue =
                                                        sumOfDoughnutData == 0
                                                            ? 0
                                                            : (
                                                                  (context.raw /
                                                                      sumOfDoughnutData) *
                                                                  100
                                                              ).toFixed(1);
                                                    const arrayLine = [
                                                        `Percentage of users: ${percentagevalue}%`,
                                                        `No. of users: ${context.raw}`,
                                                    ];
                                                    return arrayLine;
                                                },
                                                labelTextColor: function (context) {
                                                    return "#343A40";
                                                },
                                            },
                                        },
                                    },
                                    layout: {
                                        padding: {
                                            top: 0,
                                            bottom: 50,
                                        },
                                        autoPadding: false,
                                    },
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
}
